<template>
  <NavCheckAndConfirm :text="funeralDescription" :backTo="backTo">
    <template></template>
  </NavCheckAndConfirm>
</template>

<script>
import NavCheckAndConfirm from '@/common/ui/NavCheckAndConfirm'
import { willStringHelpers } from '@/common/mixins/willStringHelpers'
import { sectionDescriptions } from '@/common/mixins/sectionDescriptions'

export default {
  name: 'FuneralCheckAndConfirm',
  mixins: [willStringHelpers, sectionDescriptions],
  components: {
    NavCheckAndConfirm
  },
  computed: {
    funeral() {
      return this.$store.getters.funeral
    },
    partner() {
      return this.$store.getters.partner
    },
    backTo() {
      if (this.funeral.funeral_wishes_required) return '/funeral/wishes'
      return '/funeral/wishes_option'
    }
  },
  methods: {},
  beforeRouteLeave(to, from, next) {
    this.$store.dispatch('saveFuneral')
    this.$store.dispatch('saveDetails')
    this.$notification.success('Funeral options saved')
    next()
  }
}
</script>

<style></style>
